@use '@angular/material' as mat;
@import "@mobiscroll/angular/dist/css/mobiscroll.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Traxphere-primary: mat.define-palette(mat.$indigo-palette);
$Traxphere-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Traxphere-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Traxphere-theme: mat.define-light-theme((color: (primary: $Traxphere-primary,
				accent: $Traxphere-accent,
				warn: $Traxphere-warn,
			),
			typography: mat.define-typography-config(),
			density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Traxphere-theme);

/* You can add global styles to this file, and also import other style files */
@import 'keen-slider/keen-slider.min.css';
@import './styles//colors.scss';
@import './styles/fonts.scss';
@import './styles/mixins.scss';
@import './styles/customize-primeng.scss';

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	scrollbar-gutter: auto;
}

*:not(p-toast) {
	box-sizing: border-box;

	/* Designing for scroll-bar */
	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $--gray-100;
		border-radius: 5px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $--gray-300;
		border-radius: 5px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		// background: #555;
	}

	// remove textstyle on-autofill
	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 0s 600000s, color 0s 600000s !important;
	}

	letter-spacing: 0;
}


html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	scrollbar-gutter: auto;
}

//do not remove this. this code remove chrome input autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

app-timeline {
	width: 100%;
}

h1:not(p-toast h1, p-toast * h1),
h2:not(p-toast h2, p-toast * h2),
h3:not(p-toast h3, p-toast * h3),
h4:not(p-toast h4, p-toast * h4),
h5:not(p-toast h5, p-toast * h5),
h6:not(p-toast h6, p-toast * h6),
p:not(p-toast p, p-toast * p),
a:not(p-toast a, p-toast * a),
div:not(p-toast div, p-toast * div) {
	font-family: Inter;
	margin: 0;
}

div,
main,
section,
header,
footer {
	box-sizing: border-box;
}

// Hide input type number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

//---------------------------------------------
.iti {
	display: flex;
	width: auto;
	height: auto;
	align-items: center;
	width: 100%;
}

.iti--allow-dropdown input {
	border-radius: 0;
	padding: 7px !important;
}

.iti .dropdown-menu.country-dropdown {
	width: 100%;
	margin-top: 10px;
	border-radius: 5px;
	border-color: #c7cace;
}

.iti .dropdown-menu {
	min-width: 17rem !important;
}

.iti .iti__country-list {
	overflow-x: hidden;
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	max-height: 170px;
}

.iti__flag-container {
	// width: 100%;
	position: relative !important;
	// top: 4px;

	// border-bottom: 1px solid $--gray-200;
	&:hover {
		border: none;
		background-color: transparent;

	}
}


.iti.separate-dial-code .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag {
	width: auto !important;
	background-color: transparent;
}

.iti__flag-container+input {
	border: none;
	background-color: transparent;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #667085;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.iti__selected-flag {
	padding: 0;
}

.iti__flag-container.open+input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-bottom: 1px solid $--gray-200;
	border-radius: 0;
	width: 100%;
}

.iti .search-container input:focus {
	width: 100% !important;
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

// ngx-intl-tel-input input {
// 	padding: 10px;
// 	border-style: solid;
// 	border-width: 1px;
// 	border-color: #c7cace;
// 	border-radius: 4px;
// 	font-size: 14px;
// }

// ngx-intl-tel-input {
// 	border-radius: 8px;
// 	border: 1px solid  #D0D5DD;
// 	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
// 	background: #F9FAFB;
// }

ngx-intl-tel-input.ng-invalid.ng-touched {
	border: none;
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: $--gray-200;
}

ngx-intl-tel-input input[disabled] {
	background-color: transparent !important;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}

mbsc-timeline {
	user-select: none;
}

.mbsc-timeline-resource,
.mbsc-timeline-row {
	min-height: 89px;
}

.mbsc-flex-col .mbsc-ltr .mbsc-timeline-sidebar .mbsc-timeline-sidebar-col .mbsc-windows {
	border-bottom: unset;
	height: 80px;
	width: 120px;
}

.mbsc-timeline-sidebar-col {
	width: 120px !important;
}

.mbsc-timeline-resource-header,
.mbsc-timeline-resource-title {
	width: 100% !important;
	height: 100% !important;
}

.mbsc-windows.mbsc-popup {
	border-radius: 5px;
}

.mbsc-calendar-header .mbsc-windows {
	display: none;
}

.mbsc-windows.mbsc-schedule-time-indicator-time {
	display: none;
}

.mbsc-ios .mbsc-schedule-time-indicator-time {
	display: none;
}

.mbsc-schedule-time-indicator-y {
    border: none !important;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #2E90FA 0%, #2E90FA 50%, transparent 50%, transparent 100%);
    background-size: 0.5rem 1.6rem;
    background-repeat: repeat-y;
    width: 1px;
}  

.mbsc-windows .mbsc-timeline-header-date,
.mbsc-ios .mbsc-timeline-header-date {
	.custom-day {
		.day-number, .day-event {
			color: $--gray-900;
			text-align: center;
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}
  .active-custom-day {
    background: $--brand-600;
    padding: 0px 12px;
	color: $--white;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 8px;

	position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;

	.day-number, .day-event, .time-event {
		color: $--white;
		font-family: Inter;
		font-weight: 400;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px; 
		white-space: nowrap;
	  }
	
	  .day-number, .day-event {
		font-size: 14px;
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px; 
	  }
  }
}

.mbsc-ios .mbsc-timeline-header-date .active-custom-day {
  text-transform: uppercase;
}


.mbsc-windows .mbsc-timeline-header-active::after {
	background-color: transparent;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
	display: none;
}

.mbsc-timeline-header-month-text {
	display: flex;
	align-items: center;
	color: #101828;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	height: 40px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
	padding: 0 !important;
}

.date-picker-icon {
	position: relative;
	top: -6px;
	right: 7px;
}

.date-picker-input {
	position: relative;
	right: 15px;
}

.mat-mdc-icon-button .mat-mdc-button-ripple {
	opacity: 0 !important;
}

.block-input-icon.mat-mdc-option .mat-pseudo-checkbox-minimal,
.block-inputs.mat-mdc-option .mat-pseudo-checkbox-minimal {
	border-radius: 4px;
	border: 1px solid #1570EF;
	background: #EFF8FF;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
	color: #1570EF !important;
}

.mat-mdc-tab .mdc-tab__text-label {
	color: #344054 !important;
	font-family: Inter !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 24px !important;
	letter-spacing: 0 !important
}

mat-icon {
	overflow: visible !important;
}

.bg-color .mat-dialog-container {
	background-color: transparent !important;
	border: none;
	box-shadow: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 0;
	left: 0;
}

.fullAddress-container {
	width: 73%;
}

.modals {
	display: block;
	width: 1300px !important;
}

@media (max-width: 1600px) {
	.modals {
		display: block;
		width: 1200px !important;
	}
}

@media (max-width: 1500px) {
	.modals {
		display: block;
		width: 1200px !important;
	}

	.fullAddress-container {
		width: 66%;
	}
}

@media (max-width: 1490px) {
	.modals {
		display: block;
		width: 1000px !important;
	}
}

@media (max-width: 1400px) {
	.modals {
		display: block;
		width: 900px !important;
	}

	.fullAddress-container {
		width: 62%;
	}
}


.header {
	position: absolute;
	right: 0;
	margin: 10px;
}

.create-container {
	position: relative;
	height: 850px;
	width: auto;
	padding: 16px;
	overflow: scroll;
}

.container-icon {
	display: flex;
	width: 48px;
	height: 48px;
	padding: 11px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: 1px solid $--gray-200;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.header,
.header-inputs {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	margin-bottom: unset;
	padding: 12px;
	border-radius: 6px;
	background-color: #F9FAFB;
}

.container,
.container-information,
.footer-info {
	display: flex;
	justify-content: space-between;
	gap: 12px;
	width: 100%;
}

.details,
.payment,
.pickup,
.delivery,
.customer-broker-info,
.attacment {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 49%;
	background-color: $--gray-50;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 10px;
	position: relative;
}

.pickup,
.delivery {
	gap: 10px;
}

.upload-file {
	display: flex;
	position: relative;
	padding: 16px 24px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	align-self: stretch;
	border-radius: 12px;
	border: 1px dashed #D1E9FF;
	background: #F5FAFF;
	font-size: 14px;
}

.upload-file input {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 50;
}

.newLoad-upload-title {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.btns {
	padding: 5px 10px;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding-bottom: 0px;
	max-width: calc(100% - 70px);
}

.icon-file {
	display: flex !important;
	width: 32px !important;
	height: 32px !important;
	padding: 8px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 28px;
	border: 4px solid #EFF8FF;
	background: #D1E9FF;

}

.pick-btn {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	background: transparent;
	color: $--gray-500;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border: none;
	position: relative;

	&:hover {
		background: $--gray-100;
		cursor: pointer !important;
	}
}

.delivery-btn {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: transparent;
	color: $--gray-500;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border: none;

	&:hover {
		background: $--gray-100;
		cursor: pointer !important;
	}
}

.inactive-btn {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #F9FAFB;
	border: none;
	color: #667085;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
}

.active-btn-delivery {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #ECFDF3;
	color: #0F9D58;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border: none;
}

.manually-btn {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #EFF8FF;
	color: #175CD3;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border: none;

	&:hover {
		cursor: pointer !important;
	}
}

.coordinates-btn {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: #ECFDF3;
	color: #12B76A;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border: none;

	&:hover {
		cursor: pointer !important;
	}
}

.remove-btn {
	cursor: pointer;
	width: 20px;
	height: 25px;
}

.point-btn {
	display: flex;
}

.input-container {
	width: 100%;
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
}

.details input {
	margin-bottom: 5px;
}

input {

	background: transparent;
	padding: 5px;
	height: 100%;
	width: 100%;
	color: #667085;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0 !important;
	&:not(.p-component) {
		border: none;
		outline: none;
	}
}

.centered {
	display: flex;
	justify-content: center;
	align-items: center;
}

.block-inputs,
.block-inputs-icon-preffix {
	position: relative;
	display: flex;
	padding: 5px 14px;
	align-self: stretch;
	width: 100%;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $--gray-200;
	background: #F9FAFB;
	// box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	font-size: 14px !important;
	align-items: center;

	&:hover {
		border: 1px solid $--gray-300;
	}
}

.required {
	border-color: red;
}

.block-input-icon {
	position: relative;
	display: flex;
	padding: 5px 14px;
	width: 100%;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid $--gray-200;
	background: #F9FAFB;
	// box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	height: 40px;
	font-size: 14px !important;

	&:hover {
		border: 1px solid $--gray-300;
	}
}


.block-input-icon input[type="checkbox"],
.block-inputs input[type="checkbox"] {
	display: flex;
	width: 16px;
	height: 16px;
	padding: 2px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #1570EF;
	background: #EFF8FF;
	cursor: pointer;

	&:hover {
		transform: scale(1.05);
	}
}

.block-inputs>label {
	position: absolute;
	color: red;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	top: -12px;
	left: 13px;
	z-index: 35;
	background-color: #F9FAFB;
}

.custom-label {
	margin: 4px 0 0 10px;
	position: absolute;
	left: 30px;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: #667085;
	width: 100%;
}

.check {
	position: relative;
	width: 16px;
	height: 16px;
	padding: 2px;
	border-radius: 4px;
	border: 1px solid #1570EF;
	background: #EFF8FF;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus {
		outline: none !important;
	}

	&:checked {
		border: 1px solid #1570EF;
		background: #EFF8FF;


		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 2px;
			left: 2px;
			clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
			background-color: #1570EF;
		}
	}
}


::ng-deep .some .mat-mdc-text-field-wrapper {
	display: flex;
	height: 30px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #F9FAFB;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.md-icon-button,
.md-datepicker-input-container {
	float: left;
	margin-top: 7px;
}

.custom {
	display: flex;
	padding: 10px 14px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #F9FAFB;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.active-btn {
	background: #2563EB;
	color: #FCFCFD;
}

.footer-btn {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	gap: 12px;
}

.display-none {
	display: none !important;
}

.save-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
	margin: 16px;
	position: absolute;
	margin: 16px;
	right: 0;
	z-index: 2;
	top: 8px;
}

.save-btn {
	display: flex;
	width: 48%;
	height: 40px;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	border-radius: 8px;
	border: 1px solid #175CD3;
	background: #175CD3;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	color: #FFF;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}

.close-btn {
	display: flex;
	width: 44px;
	height: 40px;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.cancel-btn {
	width: 48%;
	border-radius: 8px;
	border: 1px solid #D0D5DD;
	background: #FFF;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

strong {
	color: #344054;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

button {
	cursor: pointer;
}

.mdc-snackbar__surface {
	padding-right: 0 !important;
}

.snackbar-success .mat-mdc-snack-bar-label {
	background-color: $--success-600 !important;
	color: #fff;
}

.snackbar-error .mat-mdc-snack-bar-label {
	background-color: $--error-600;
	color: #fff;
}

.snackbar-warning .mat-mdc-snack-bar-label {
	background-color: $--warning-600;
	color: #fff;
}


.block-input-icon-with-err {
	position: relative;
	display: flex;
	padding: 5px 14px;
	align-self: stretch;
	border-radius: 8px;
	border: 1px solid $--gray-200;
	// background: #F9FAFB;
	// box-shadow: 0px 1px 2px 0px #1018280D;
	// margin-right: 5px;
	margin-bottom: 5px;
	height: 43px;
	align-items: center;

	.input-err {
		color: red;
		position: absolute;
		top: 48px;
		left: 0;
		font-size: 12px;
		height: 13px;

		.ng-star-inserted {
			display: flex;
			align-items: center;
			gap: 5px;

			svg-icon {
				height: 15px !important;
			}
		}

		svg {
			width: 13px;
			height: 15px;

			path {
				fill: red;
			}
		}
	}

	svg-icon {
		height: 16px;
	}

	.date-picker-icon {
		position: relative;
		top: 2px;
		right: 7px;
	}
}

mat-form-field {
	.custom-err-select {
		position: absolute;
		top: -20px;
		left: 1px;

		mat-error {
			display: flex;
			align-items: center;

			svg {
				width: 13px;
				height: 15px;
				margin-top: 3px;
				margin-right: 4px;

				path {
					fill: red;
				}
			}
		}
	}

	.mat-mdc-select-arrow-wrapper {
		display: none;
	}
}

.global-btn {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	border-radius: 4px;
	color: white;
	border: none;
	padding: 10px 16px 10px 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	border: none;
	opacity: 1;

	&:hover {
		cursor: pointer;
	}

	.btn-icon {
		width: 20px;
		height: 20px;
	}

	&.disabled-btn {
		opacity: 0.5;
		pointer-events: none;

		&:hover {
			cursor: unset !important;
		}
	}

	&:focus-visible {
		outline: none !important;
	}
}

.disabled-btn {
	opacity: 0.5;

	&:hover {
		cursor: unset !important;
		// pointer-events: none;
	}
}

//-----------------------global radio btn-----------------------//

.global-radio-btn {
	.mdc-radio__inner-circle {
		top: -1px !important;
		left: -1px !important;
	}

	.mdc-radio__outer-circle {
		width: 18px !important;
		height: 18px !important;
	}

	.mdc-radio:hover {
		background-color: white !important;
	}

	.mdc-radio__background::before {
		transform: scale(0) !important;
	}

	.mat-radio-ripple {
		display: none;
	}

	.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
		background-color: $--brand-600 !important;
	}

	.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
		background-color: white !important;
	}

	.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
		border-color: $--brand-600 !important;
		border-width: 1px;
	}

	label {
		color: $--gray-700;
		@include text-xs;
		font-weight: 500;
		font-family: Inter;
	}

	.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
		border-color: $--brand-600 !important;
		border: 1px solid $--gray-300;
	}

	.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
		border-color: $--brand-600 !important;
		transform: scale(0.4) !important;
	}

	.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__inner-circle {
		transform: scale(0, 0) !important;
	}
}

//-----------------------global select input-----------------------//

.global-select-input {
	.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
		display: flex;
		align-items: center;
		height: 44px;
		border: 1px solid $--gray-200;
		border-radius: 8px;
		background: white;
		// box-shadow: 0px 1px 2px 0px #1018280D;
		position: relative;

		label {
			@include text-md;
			font-weight: 400;
			color: $--gray-500 !important;
			font-family: Inter;
		}

		.mdc-line-ripple {
			display: none;
		}

		& {
			.mat-mdc-form-field-focus-overlay {
				background-color: white !important;
				opacity: unset !important;
			}
		}
	}

	.mat-mdc-form-field-subscript-wrapper {
		&> :not(.mat-error) {
			height: 0;
		}
	}

	.mat-mdc-select-min-line {
		font-size: 16px;
		font-family: Inter;
		font-weight: 400;
		line-height: 24px;
		color: #667085;
	}
}

.email-user-input {
	width: 100%;
	margin-bottom: 30px;

	input[type=email] {
		border: none;
		outline: none;
		background: transparent;
		padding: 5px;
		height: 100%;
		width: 100%;
	}

	input::placeholder {
		@include text-md;
	}

	mat-icon {
		height: 20px;
	}
}


.form-caption {
	font-family: Inter;
	@include text-sm;
	font-weight: 500;
	line-height: 20px;
	color: $--gray-700;
	margin-bottom: 16px;
}

.popup-footer-btns {
	width: 100%;
	display: flex;
	justify-content: center;

	// padding-bottom: 24px;
	.actions-btns {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 15px;
		width: 352px;
		height: 44px;

		.btn-cancel {
			background-color: $--white;
			border: 1px solid $--gray-300;
			color: $--gray-700;

			&:focus {
				outline: 0
			}
		}

		.btn-confirm {
			border: 1px solid $--brand-600;
			background-color: $--brand-600;
			color: $--white;

			&:focus {
				outline: 0
			}
		}
	}
}

/* Скрываем стандартный чекбокс */
.custom-checkbox {
	display: none !important;
}

/* Стили для label */
.custom-checkbox+label {
	display: flex;
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	position: relative;
	cursor: pointer;
	border-radius: 6px;
	border: 1px solid var(--Primary-colors-Gray-300, #D0D5DD);
	background: var(--Primary-colors-White, #FFF);
	transition: all 0.3s;
}

/* Стили для checked состояния */
.custom-checkbox:checked+label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	// padding: 3px;
	border-radius: 6px;
	border: 1px solid var(--Primary-colors-Brand-700, #175CD3);
	background: var(--Primary-colors-Brand-50, #EFF8FF);
}

/* Иконка внутри чекбокса */
.custom-checkbox:checked+label:after {
	content: url('/assets/svg/checked.svg');
	// display: inline-block;
	// width: 20px;
	// /* Скорректируйте размер иконки, если необходимо */
	height: 100%;
	// position: absolute;
	// /* Добавляем позиционирование для центрирования иконки */
	// top: 44%;
	// left: 50%;
	// transform: translate(-50%, -50%);
}

.custom-checkbox.minus:checked+label:after {
	content: url('/assets/svg/checkbox-minus.svg');
	display: flex;
	justify-content: center;
	// width: 14px;
	// /* Скорректируйте размер иконки, если необходимо */
	// height: 14px;
	position: absolute;
	/* Добавляем позиционирование для центрирования иконки */
	// top: 41%;
	// left: 50%;
	// transform: translate(-50%, -50%);
}

.codriver-tooltip,
.role-tooltip, .type-tooltip {
	font-size: 12px;
	font-family: Inter;
	font-weight: 500;
	transition: visibility 0.3s linear, opacity 0.3s linear;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	left: 0;
	align-items: start;
	color: #344054;

	.codriver-name {
		color: #1b5fd4;
		font-weight: 400;
	}
}

.green-badge{
	@include badge(
		$background-color: $--success-50,
		$text-color: $--success-600,
		$padding: 1px 7px,
		$border-radius: 15px,
		$font-size: 12px,
		$font-weight: 500,
		$border-color: unset
	);
}
.orange-badge{
	@include badge(
		$background-color: $--warning-50,
		$text-color: $--warning-500,
		$padding: 1px 7px,
		$border-radius: 15px,
		$font-size: 12px,
		$font-weight: 500,
		$border-color: unset
	);
}
.blue-badge{
	@include badge(
		$background-color: $--brand-50,
		$text-color: $--brand-700,
		$padding: 1px 7px,
		$border-radius: 15px,
		$font-size: 12px,
		$font-weight: 500,
		$border-color: unset
	);
}
  
.type-tooltip {
	width: 170px !important;
    height: 180px !important;
}


.select-country {
	white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 90%;
	.mdc-line-ripple--active::after {
		display: none !important;
	}

	.mdc-text-field--filled .mdc-line-ripple::before {
		border-bottom-width: 0px !important;
	}

	&>* {
		font-size: 16px;
		font-weight: 400;
		font-family: Inter;
		text-align: center;
	}

	mat-form-field {
		width: 100%;

		.mat-mdc-text-field-wrapper {
			border: 1px solid $--gray-200;
			border-radius: 8px;
			background-color: white;

			.mat-mdc-form-field-flex {
				flex-direction: row-reverse;
				height: 42px;

				.mat-mdc-form-field-icon-suffix {
					height: 42px;
					width: 80px;
					display: flex;
					align-items: center;
					position: relative;
					left: -22px;
					background: url('assets/svg/chevron-down.svg') no-repeat scroll 52px 11px;
				}

				.mat-mdc-form-field-infix {
					// min-height: 44px;
					padding: unset !important;
					left: -15px;
					bottom: 3px;

					input[type=text] {
						height: 48px;
						font-size: 16px;
						font-weight: 400;
						font-family: Inter;
						color: $--gray-500;
					}
				}
			}

			.mat-mdc-form-field-focus-overlay {
				background-color: white !important;
				opacity: unset !important;
			}
		}
	}
}

.select-country.fix-row-3 {
	.mat-mdc-form-field-icon-suffix {
		background: url('assets/svg/chevron-down.svg') no-repeat scroll 45px 11px !important;
	}

	.mat-mdc-form-field-infix {
		left: 0px !important;
		// min-height: 44px;
	}
}

.country-err {
	position: absolute;
	top: 25px;
	left: 0px;
	font-size: 12px;
	height: auto;

	mat-error {
		display: flex;
		align-items: center;

		svg {
			width: 13px;
			height: 15px;
			margin-top: 3px;
			margin-right: 4px;

			path {
				fill: red;
			}
		}
	}
}

.mbsc-windows.mbsc-timeline-day::after,
.mbsc-windows.mbsc-timeline-slots,
.mbsc-windows.mbsc-timeline-slot-header,
.mbsc-windows.mbsc-timeline-header-month,
.mbsc-windows.mbsc-timeline-header-week,
.mbsc-windows.mbsc-timeline-footer-week,
.mbsc-windows.mbsc-timeline-header-date,
.mbsc-windows.mbsc-timeline-header-column,
.mbsc-windows.mbsc-timeline-header,
.mbsc-windows.mbsc-timeline-footer-date,
.mbsc-windows.mbsc-timeline-footer-column,
.mbsc-windows.mbsc-timeline-footer,
.mbsc-windows.mbsc-timeline-resource,
.mbsc-windows.mbsc-timeline-resource-header-cont,
.mbsc-windows.mbsc-timeline-resource-footer-cont,
.mbsc-windows.mbsc-timeline-sidebar-resource,
.mbsc-windows.mbsc-timeline-sidebar-header-cont,
.mbsc-windows.mbsc-timeline-sidebar-footer-cont,
.mbsc-windows.mbsc-timeline-column,
.mbsc-windows.mbsc-timeline-row,
.mbsc-windows.mbsc-schedule-resource-group,
.mbsc-windows.mbsc-schedule-resource,
.mbsc-windows.mbsc-schedule-time-col,
.mbsc-windows.mbsc-schedule-all-day-item::after,
.mbsc-windows.mbsc-schedule-column,
.mbsc-windows.mbsc-schedule-item,
.mbsc-windows.mbsc-timeline-row-group::after,
.mbsc-windows.mbsc-timeline-row-date {
	border-color: $--gray-200;
}


//fix mat-select && autocomplete dropdowns
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
	position: absolute;
	right: 4px;
}

.cdk-overlay-pane div.mat-mdc-autocomplete-panel {
	position: absolute;
	right: 4px;
}

// .cdk-overlay-container .cdk-overlay-pane .dropDwonMultipleFixClass {
// 	position: absolute;
// 	top: 0px;
// 	left: -4px;
// }

// .cdk-overlay-container .cdk-overlay-pane .dropDownNoteFixClass {
// 	position: absolute;
// 	top: 10px;
// 	right: 3px;
// }

// .cdk-overlay-container .cdk-overlay-pane .dropDownCreateLoadFixClass {
// 	position: absolute;
// 	top: 0px;
// 	right: 3px;
// }

// .cdk-overlay-container .cdk-overlay-pane .dropDownEditLoadFixClass {
// 	position: absolute;
//     top: 18px;
//     right: 4px;
// }

#allOption,
#statusOption {
	padding: 0 14px;
	background-color: unset !important;

	.mat-pseudo-checkbox {
		display: none;
	}

	.mdc-list-item__primary-text {
		display: flex;
		width: 100%;
		gap: 5px;
		align-items: center;
		margin: unset;
		flex-direction: row-reverse;
		justify-content: flex-end;

		.dl-label-style {
			cursor: pointer;
			overflow-wrap: break-word;
			width: calc(100% - 20px);
			font-family: Inter;
			color: $--gray-900;
			font-weight: 500;
			@include text-md;
		}

		.status-counter {
			@include badge($background-color: $--brand-50,
				$text-color: $--brand-600,
				$padding: 1px 7px,
				$border-radius: 15px,
				$font-size: 12px,
				$font-weight: 500,
				$border-color: unset);
		}
	}
}

#statusOption {
	padding: 0 5px;

	.mat-pseudo-checkbox {
		display: none;
	}

	.mdc-list-item__primary-text {
		display: flex;
		width: 100%;
		gap: 5px;
		align-items: center;
		margin: unset;
		justify-content: space-between;
		flex-direction: row-reverse;
		color: $--gray-900;
		font-weight: 500;
		@include text-md;

		.status-counter {
			@include badge($background-color: $--brand-50,
				$text-color: $--brand-600,
				$padding: 1px 7px,
				$border-radius: 15px,
				$font-size: 12px,
				$font-weight: 500,
				$border-color: unset);
		}
	}
}

.mat-calendar-body-selected {
	background-color: #175CD3 !important;
}

.mat-calendar-body-in-range::before {
	background-color: #EFF8FF !important;
}

.cdk-overlay-pane .mat-datepicker-popup {
	top: 51px !important;
	left: 289px !important
}

.mbsc-ios-dark.mbsc-popup-body {
	background: #FFF;
	color: #000000;
}

.mbsc-ios-dark.mbsc-popup-arrow {
	background: #FFF;
}

.mbsc-calendar-header .mbsc-ios {
	display: none;
}

.fix-create-load-popup {
	.mat-mdc-form-field-flex {
		max-height: 44px;
	}

	.mat-mdc-select-min-line {
		font-size: 14px !important;
		top: -22px !important;
	}

	.mat-mdc-tab-labels>*:not(:first-child) {
		display: none;
	}
}

.fix-edit-load-popup {
	.mat-mdc-form-field-flex {
		max-height: 44px;
	}

	.mat-mdc-select-min-line {
		font-size: 14px !important;
		position: relative;
	}

	.mat-mdc-select-trigger {
		position: static !important;
	}

	.mat-mdc-select-arrow-wrapper {
		display: none;
	}
}

.popover-container:has(.manage-edit-tooltip),
.popover-container:has(.loads-edit-tooltip) {
	width: 130px;
	height: 80px !important;
	display: flex !important;
	flex-direction: column !important;
	border-radius: 8px !important;
	padding: unset !important;
	display: flex;
	height: 100%;
	flex-direction: column;
	border: 1px solid #F2F4F7;
	box-shadow: 0px 12px 16px -4px rgba(52, 64, 84, 0.08), 0px 4px 6px -2px rgba(52, 64, 84, 0.03) !important;

	ul {
		padding-inline-start: unset !important;
		margin-block-start: unset !important;
		margin-block-end: unset !important;
		list-style: none;
	}

	li {
		@include flex-align-center;
		gap: 10px;
		height: 40px;
		width: 100%;
		padding: 0 12px;
		cursor: pointer;
		pointer-events: all;

		.edit-icon {
			height: 20px;
			width: 20px;
		}

		p {
			@include text-sm;
			color: #344054;
			white-space: nowrap;
		}
	}
}

.popover-container:has(.loads-edit-tooltip) {
	height: 125px !important;
	width: 120px !important;

	.edit-icon {
		path {
			stroke: #667085;
		}
	}
}

.popover-container:has(.eq-tooltip) {
	width: 140px !important;
}

.country-list-item {
	font-size: 16px;
	font-weight: 400;
	font-family: Inter;
	color: #667085;
}