@import './colors.scss';

@mixin flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-column {
    display: flex;
    flex-direction: column;
}
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-align-center {
    display: flex;
    align-items: center;
}
@mixin inline-flex-center {
    display: inline-flex;
    align-items: center;
}
@mixin inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}

@mixin shadow-3xl {
    box-shadow: 0px 32px 64px -12px #10182824;
}
@mixin shadow-2xl {
    box-shadow: 0px 24px 48px -12px #1018282e;
}
@mixin shadow-xl {
    box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
}
@mixin shadow-lg {
    box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
}
@mixin shadow-md {
    box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;
}
@mixin shadow-sm {
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
}
@mixin shadow-xs {
    box-shadow: 0px 1px 2px 0px #1018280d;
}

@mixin link {
    text-decoration: none;
    color: $--brand-700;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

@mixin hover-scale-05 {
    &:hover {
        transform: scale(1.05);
    }
}

@mixin outline {
    border: 1px solid $--gray-300;
    border-radius: 8px;
    background-color: $--white;
    transition: .15s;
    &:hover {
        border: 1px solid $--gray-400;
    }
}

@mixin bordered($borderRadius, $borderWidth, $borderColor) {
    border: $borderWidth solid $borderColor;
    // border: 1px solid $--gray-300;
    border-radius: $borderRadius;
    background-color: $--white;
    transition: .15s;
    &:hover {
        border: 1px solid $--gray-300;
    }
}

@mixin brand-button {
    background-color: $--brand-600;
    color: $--white;
    border: none;
    border-radius: 8px;
    transition: .15s;
    &:hover {
        background-color: $--brand-700;
    }
}

@mixin delete-button {
    background-color: $--error-600;
    color: $--white;
    border: none;
    border-radius: 8px;
    transition: .15s;
  
    &:hover {
      background-color: $--error-700;
    }
  
    &.disabled-btn {
      pointer-events: none;
      opacity: 0.6;
  
      &:hover {
        background-color: $--error-600;
      }
    }
  }
  
@mixin badge($background-color, $text-color, $padding, $border-radius, $font-size, $font-weight, $border-color) {
	display: inline-block;
	padding: $padding;
	font-size: $font-size;
	font-weight: $font-weight;
	color: $text-color;
	text-align: center;
	line-height: 1.7;
	white-space: nowrap;
	border-radius: $border-radius;
	background-color: $background-color;
    height: fit-content;
    border: 1px solid $border-color;
}

@mixin point($width, $height, $borderRadius, $background) {
    width: $width;
    height: $height;
    border-radius: $borderRadius;
    background-color: $background;
}

@mixin hr-line($width: 100%, $height: 2px, $color: #000, $style: solid, $margin: 1rem 0) {
    width: $width;
    height: $height;
    background-color: $color;
    border: none;
    border-top: $height $style $color;
    margin: $margin;
}
