@import './fix-p-toast.scss';

/* Remove box-shadow for all specified buttons */
.p-multiselect,
.p-dropdown,
.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-success:enabled:focus,
.p-button.p-button-info:enabled:focus,
.p-button.p-button-warning:enabled:focus,
.p-button.p-button-help:enabled:focus,
.p-button.p-button-danger:enabled:focus {
  box-shadow: none !important;
}

.p-element .p-button {
  box-shadow: none !important;
}

// Dropdown size
.p-dropdown-label, .p-multiselect-label {
  padding: 0.5rem 0.75rem !important;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
  .p-dropdown-item {
    margin: 4px !important;
    padding: 0.5rem 0.75rem !important;
    border-radius: 4px !important;
  }
}

.p-multiselect-panel {
  .p-multiselect-header {
    padding: 0.5rem 0.5rem 0 0.5rem !important;
    border-bottom: 0 none !important;
    color: #334155 !important;
    background: #ffffff !important;
  }
  .p-multiselect-items {
    padding: 0.25rem 0.25rem !important;
    .p-multiselect-item {
      margin: 2px 0 !important;
      padding: 0.5rem 0.75rem !important;
      border-radius: 4px !important;
    }
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

//radio btn
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-listbox .p-listbox-list {
  padding: 0.25rem 0.25rem;
  outline: 0 none;
  .p-listbox-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: #334155;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
}

// Calendar spacing
.p-datepicker {
  table td {
    padding: 0.25rem !important;
    & > span {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
      border: 1px solid transparent;
    }
  }
  .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    font-weight: 600;
  }

  table th > span {
    font-weight: 600;
  }
}

.p-tieredmenu {
  .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #334155;
    padding: 0.5rem 0.75rem;
    -webkit-user-select: none;
    user-select: none;
    margin: 0 0.25rem !important;
  }

  .p-menuitem > .p-menuitem-content {
    color: #334155;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
    width: 95%;
    margin: 0 auto;
  }
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #94a3b8;
}

.p-dropdown:not(.p-disabled).p-focus, .p-multiselect:not(.p-disabled).p-focus {
  border-color: #3B82F6;
}

.p-dropdown:not(.p-disabled):not(:focus):hover, .p-multiselect:not(.ng-dirty.ng-invalid):hover, .p-inputtext:enabled:not(:focus):hover, p-inputnumber > :not(.ng-invalid):not(:focus):hover {
  border-color: #94a3b8 !important;
}

.p-multiselect-items {
  padding: 0.25rem 0.25rem !important;
}

.p-button.p-button-secondary.p-button-outlined, .p-button-group.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  border: 1px solid #e2e8f0 !important;
}

/* Remove box-shadow and focus effects for input fields */
.p-inputtext:enabled:focus,
.p-inputmask.p-variant-filled:enabled:focus,
p-inputnumber.p-inputnumber.p-variant-filled > .p-inputnumber-input:enabled:focus {
  box-shadow: none !important;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem !important;
}

.p-icon-field-left > .p-inputtext {
  padding-left: 2.5rem !important;
}